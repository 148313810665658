import { configureStore } from "@reduxjs/toolkit";
import { BattleDataReducer } from "./reducre/BattleDataReducer";
import { LoginReucer } from "./reducre/LoginReducer";
import { R_WalletBalanceReducer } from "./reducre/R_WalletBalanceReducer";
import { UserDataReducer } from "./reducre/UserDataReducer";
import { WalletReducer } from "./reducre/WalletReducer";
import { WinWalletReducer } from "./reducre/WinWalletReducer";

export const store = configureStore({

    
    
    reducer: {
        login: LoginReucer,
        UserData: UserDataReducer,
        Wallet: WalletReducer,
        BattleData: BattleDataReducer,
        R_Wallet : R_WalletBalanceReducer,
        WinWallet : WinWalletReducer
    }
    
},
{});

// It opens Wallet Reducer only
