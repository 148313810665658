export const login = (con) => {
    return (dispatch) => {
        dispatch({
            type: "login",
            playload: con
        })
    }
}


export const AddWalletAmount = (PrevAmnt, data) => {
    return (dispatch) => {
        dispatch({
            type: "ADD_WALLET",
            playload: { PrevAmnt, data }
        })
    }
}

export const RemoveWalletAmount = (PrevAmnt, data) => {
    return (dispatch) => {
        dispatch({
            type: "REMOVE_WALLET",
            playload: { PrevAmnt, data }
        })
    }
}



export const UserDataAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: "userdata",
            playload: data
        })
    }
}


export const BattleDataAction = (data) => {
    return (dispatch) => {
        dispatch({
            type: "SETBATTLEDATA",
            playload: data
        });
    }
}


export const R_WalletAction = (Balance) => {
    return (dispatch) => {
        dispatch({
            type: "GET_BALANCE",
            playload: Balance
        })
    }
}



export const Win_WalletAction = (Balance) => {
    return (dispatch) => {
        dispatch({
            type: "GET_WIN_BALANCE",
            playload: Balance
        })
    }
}


// 500.00 GET Working //