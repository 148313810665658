import { bindActionCreators } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect, useState } from "react";
import { Collapse, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createGame } from "../action/CommonActions";
import LatestUserData from "../action/LatestUserData";
import { profile_pic_path } from "../define/Define";
import { ActionCreator } from "../state";
import { conn } from "../action/Socket";
// var conn = new WebSocket('wss://localhost:8080', [], {
//     rejectUnauthorized: false
// });

const RequestAudio = new Audio("/assets/audio/battle_request.mp3");
const BattleAccepted = new Audio("/assets/audio/battle_accepted.mp3");
conn.onopen = function (e) {
    console.log("Connected");
};
function Lobby() {
    const user_data = LatestUserData();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const WalletSetter = bindActionCreators(ActionCreator, dispatch);
    const Walletselector = useSelector(state => state.Wallet);
    const WinWalletselector = useSelector(state => state.WinWallet);
    const [wallet_amount, setwallet_amount] = useState(Walletselector);
    const [battleAmount, setbattleAmount] = useState("");
    const [battleRoom, setbattleRoom] = useState("");
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const handleCloseOffcanvas = (state) => setShowOffcanvas(state);

    // Runnung , Different , Self Created Battles Main Data Manager
    const [battleCreated, setbattleCreated] = useState([]);
    const [loadDifferentUserChallenges, setloadDifferentUserChallenges] = useState([]);
    const [loadRunning, setloadRunning] = useState([]);

    const [showModal, setshowModal] = useState(false);
    const [UserAcceptedDetails, setUserAcceptedDetails] = useState([]);

    // CREATE BATTLE BUTTON SHOW HIDE //
    const [open, setOpen] = useState(false);


    const handleModalActions = (val) => {
        setshowModal(val);
    };


    useEffect(() => {
        userBattleLoader(setbattleCreated, battleCreated, setloadDifferentUserChallenges, loadDifferentUserChallenges, setloadRunning, loadRunning);
    }, [navigate]);


    // Socket Function 
    useEffect(() => {
        conn.onmessage = function (e) {
            e.data.text().then(data => {
                const received_data = JSON.parse(data);

                if (received_data.type === "CLAIM_BATTLE") {
                    if (received_data.data.winner === user_data.uid) {
                        WalletSetter.Win_WalletAction(WinWalletselector + parseInt(received_data.data.win_prize));
                    }
                }
                else if (received_data.type === "CANCEL_BATTLE") {
                    if (received_data.data.opponent === user_data.uid) {
                        WalletSetter.AddWalletAmount(Walletselector, parseInt(received_data.data.amount));
                    }
                }
                else if (received_data.type === "START_BATTLE") {
                    setloadRunning(prevItems => [...prevItems, received_data.data]);
                    setloadDifferentUserChallenges(prevItems => prevItems.filter(item => item.battle_id !== received_data.data.battle_id));
                    if (received_data.data.opponent === user_data.uid) {
                        BattleAccepted.play();
                        toast.success(`Challenge Accepted !!`);
                        WalletSetter.RemoveWalletAmount(received_data.data.new_wallet, 0);
                        WalletSetter.Win_WalletAction(received_data.data.new_win_wallet);
                        navigate(`/room_code/${received_data.data.battle_id}`, { replace: true });
                    }
                }
                else if (received_data.type === "CREATE_BATTLE") {
                    setloadDifferentUserChallenges(prevItems => [...prevItems, received_data.data]);
                }
                else if (received_data.type === "DELETE_BATTLE") {
                    setloadDifferentUserChallenges(prevItems => prevItems.filter(item => item.battle_id !== received_data.data[0].battle_id));
                }
                else if (received_data.type === "REQ_BATTLE_JOIN") {
                    if (received_data.data.opponent === user_data.uid) {
                        handleModalActions(true);
                        RequestAudio.play();
                        setUserAcceptedDetails([received_data.data]);
                    }
                }
                else if (received_data.type === "REQ_BATTLE_CANCEL") {
                    if (received_data.data.opponent === user_data.uid) {
                        handleModalActions(false);
                    }
                }
                else if (received_data.type === "DECLINE_BATTLE_REQ") {
                    const data = received_data.data;
                    if (data.user_id === user_data.uid) {
                        toast.error("Joining Req Rejected");

                        setloadDifferentUserChallenges(prevItems => {
                            const updater = prevItems.find(obj => obj.battle_id === data.battle_id);
                            const updatedObjCopy = {
                                ...updater,
                                cancelled: true
                            }

                            const newState = prevItems.map(obj => {
                                if (obj.battle_id === data.battle_id) {
                                    return updatedObjCopy;
                                } else {
                                    return obj;
                                }
                            });
                            setDiffrentBattle(newState);
                            return newState
                        });
                        function setDiffrentBattle(data) {
                            setloadDifferentUserChallenges(data);
                        }
                    }
                }
            });
        };
    }, [conn]);


    const declineJoinRequest = (user_id, amount, battle_id) => {
        conn.send(JSON.stringify({
            type: "DECLINE_BATTLE_REQ",
            data: { user_id, amount, battle_id }
        }));
    }

    const acceptJoinRequest = (user_id, battle_id, profile_pic, accepter, amount) => {
        handleModalActions(false);
        // document.getElementById("loadingScreen").classList.add("active");
        navigate(`/room_code_waiting`, { replace: true });
        axios.post("/server/joinGame.php", { battle_id, user_id })
            .then(data => {
                const response = data.data;
                if (response.status === 100) {
                    toast.success("Joined Successfully");
                    conn.send(JSON.stringify({
                        type: "START_BATTLE",
                        data: {
                            opponent: user_id,
                            user_name: user_data.uname,
                            battle_id,
                            accepter,
                            challenger: user_data.uname,
                            accepter_pic: profile_pic,
                            challenger_pic: user_data.profile_pic,
                            amount,
                            new_win_wallet: response.opponent_win_wallet,
                            new_wallet: response.opponent_wallet
                        }
                    }));
                    WalletSetter.RemoveWalletAmount(response.user_wallet, 0);
                    WalletSetter.Win_WalletAction(response.user_win_wallet);
                    navigate(`/room_code/${battle_id}`, { replace: true });
                    // document.getElementById("loadingScreen").classList.remove("active");
                } else if (response.status === 101) {
                    toast.error(response.msg);
                }
            });
    }

    function callCreateGame() {
        createGame(wallet_amount, battleAmount, battleRoom, setbattleCreated, battleCreated, (data) => {
            data.msg.challenger = user_data.uname;
            // WalletSetter.RemoveWalletAmount(wallet_amount, battleAmount);
            // setwallet_amount(wallet_amount - battleAmount);
            setbattleAmount("");
            setbattleRoom("");
            
            if (data.status === 100) {
                conn.send(JSON.stringify({
                    type: "CREATE_BATTLE",
                    data: data.msg
                }));
            }
        });
        setOpen(!open)
    }

    return (
        <>
            <div id="loadingScreen" >
                <div className="text-center">
                    <img src="/assets/images/loader.png" className="img-fluid searching_player" alt="Loading Player" />
                    <h3 className="text-white">Ready To Go</h3>
                </div>
            </div>

            <div className="main-area" style={{ paddingTop: "60px" }}>
                {
                    user_data && user_data.game_creator ? (
                        <>
                            {
                                UserAcceptedDetails.length ? (
                                    <Modal show={showModal} onHide={() => { handleModalActions(false); declineJoinRequest(UserAcceptedDetails[0].joiner, UserAcceptedDetails[0].amount); }}>
                                        <Modal.Body>
                                            <div className="row">
                                                <div className="col-12 text-center mb-3">
                                                    <span className="ml-1" style={{ color: "brown" }}>
                                                        Playing For
                                                        <img src="/assets/images/global-rupeeIcon.png" className="ml-1" width="21px" alt="rupeeIcon" />
                                                        <span className="betCard-amount">{UserAcceptedDetails[0].amount}</span>
                                                    </span>
                                                </div>
                                                <div className="col-4 text-center">
                                                    <img src={profile_pic_path + user_data.profile_pic} className="img-fluid" alt="Current User Profile" width="40px" /><br />
                                                    <small className="noBreak">{user_data.uname}</small>
                                                </div>
                                                <div className="col-4 text-center">
                                                    <img src="/assets/images/vs.png" className="img-fluid" alt="Verses" width="40px" />
                                                </div>
                                                <div className="col-4 text-center">
                                                    <img src={profile_pic_path + UserAcceptedDetails[0].profile_pic} className="img-fluid" alt="Loading Player" width="40px" /><br />
                                                    <small className="noBreak">{UserAcceptedDetails[0].user_name}</small>
                                                </div>
                                                <div className="col-12 d-flex justify-content-around mt-3">
                                                    <button className="btn btn-success" onClick={() => acceptJoinRequest(UserAcceptedDetails[0].joiner, UserAcceptedDetails[0].battle_id, UserAcceptedDetails[0].profile_pic, UserAcceptedDetails[0].user_name, UserAcceptedDetails[0].amount)} >ACCEPT</button>
                                                    <button className="btn btn-danger" onClick={() => { handleModalActions(false); declineJoinRequest(UserAcceptedDetails[0].joiner, UserAcceptedDetails[0].amount, UserAcceptedDetails[0].battle_id); }}>REJECT</button>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                ) : null
                            }
                            {/* ================================================================================ */}

                            <div className="mt-2 px-2">
                                {/* Button to trigger the collapse */}
                                <div
                                    className="text-center text-white py-2 mb-3 shadow-sm rounded-2 cursor-pointer"
                                    onClick={() => setOpen(!open)}
                                    aria-controls="collapseCard"
                                    aria-expanded={open}
                                    style={{
                                        backgroundColor: open ? "#E90042" : "#FF9D3D",
                                        transition: "background-color 0.3s",
                                    }}
                                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = open ? "#c80036" : "#e68a32"}
                                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = open ? "#E90042" : "#FF9D3D"}
                                >
                                    {open ? "Close" : "CREATE A BATTLE"}
                                </div>


                                {/* Collapsible card */}
                                <Collapse in={open}>
                                    <div id="collapseCard" className="collapseCard-container">
                                        <div className="collapseCard">
                                            <div style={{ textDecoration: "none" }}>
                                                <div className="collapseCard-body w-100 p-3">
                                                    <div className="d-flex flex-column mt-2 m-0 w-100">
                                                        <div className="d-flex flex-column gap-2 mt-2">
                                                            <label htmlFor="battleAmountInput" className="form-label">Amount</label>
                                                            <input
                                                                type={"number"}
                                                                value={battleAmount}
                                                                onChange={(e) => setbattleAmount(e.target.value)}
                                                                id="battleAmountInput"
                                                                min="0"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column gap-2 mt-2">
                                                            <label htmlFor="battleRoomInput" className="form-label">Room Code</label>
                                                            <input
                                                                type={"number"}
                                                                value={battleRoom}
                                                                onChange={(e) => setbattleRoom(e.target.value)}
                                                                id="battleRoomInput"
                                                                required
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column gap-2 mt-2">
                                                            <label htmlFor="" className="form-label"></label>
                                                            <button
                                                                className="btn btn-success btn-block"
                                                                onClick={callCreateGame}
                                                            >
                                                                Set
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="collapseCard-header" style={{ right: "35%", transition: "left 0.3s ease 0s" }}>
                                                <picture>
                                                    <img height="14px" width="14px" src="/assets/images/WhiteStroke.png" alt="" />
                                                </picture>
                                                <div className="collapseCard-title ml-1 mt-1">CREATE A BATTLE</div>
                                                <picture>
                                                    <img height="14px" width="14px" src="/assets/images/WhiteStroke.png" className="ml-1" alt="" />
                                                </picture>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                            {/* ================================================================================ */}
                        </>
                    ) : null
                }
                <div className="row mt-3">
                    <div className="px-4 py-3 col-12">
                        <div className="mb-0">
                            <img src="/assets/images/WhiteStroke.png" width="20px" alt="battleIconWhiteStroke" />
                            <span className="ml-2 games-section-title">Open Battles</span>
                            <span onClick={() => handleCloseOffcanvas(!showOffcanvas)} className="games-section-headline text-uppercase position-relative mt-2 font-weight-bold float-right" style={{ top: "-0.2rem" }}>
                                Rules
                                <img className="ml-2" src="/assets/images/global-grey-iButton.png" alt="global-grey-iButton" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        battleCreated && battleCreated.length > 0 ?
                            battleCreated.map((data, index) => {
                                return (
                                    <CreatedRoom battleRemover={setbattleCreated} AllBattles={battleCreated} key={index} data={data} created={true} />
                                )
                            })
                            : null
                    }
                    {
                        loadDifferentUserChallenges && loadDifferentUserChallenges.length > 0 ? (
                            loadDifferentUserChallenges.map((data, index) => {
                                return (
                                    <LoadDifferentChallenges key={index} data={data} created={false} runningBattle={loadRunning} />
                                )
                            })
                        ) : (
                            <div className="px-4 col-12">
                                <div className="mt-1">
                                    <div className="pl-3">
                                        <div>
                                            <h5 className="no_match_text text-center text-danger">No Active Matches Were Found !</h5>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="row mt-3">
                    <div className="px-4 py-3 col-12">
                        <div className="mb-0">
                            <img src="/assets/images/WhiteStroke.png" width="20px" alt="battleIconWhiteStroke" />
                            <span className="ml-2 games-section-title">Running Battles</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {

                        loadRunning.length > 0 ? (
                            loadRunning.map((data, index) => {
                                return (
                                    <RunningBattles key={index} data={data} />
                                )
                            })
                        ) : (
                            <div className="px-4 col-12">
                                <div className="mt-1">
                                    <div className="pl-3">
                                        <div>
                                            <h5 className="text-center text-danger no_match_text">No Active Matches Were Found !</h5>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <Offcanvas show={showOffcanvas} onHide={() => handleCloseOffcanvas(!showOffcanvas)} animation="slide" placement="bottom">
                    <Offcanvas.Header>
                        <Offcanvas.Title className="text-center">Updated Game Rules ( From 15th March 2023 ) </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="card">
                            <div className="card-body">
                                <p><small>अगर गेम Join करने के बाद opponent का एक भी टोकन ओपन हो जाता है और आप किसी भी कारण से तुरंत लेफ्ट करते हैं तो आपको सीधा 30% Loss कर दिया जायेगा! यदि आप जान भूजकर Autoexit करते हैं तो भी आपको 100% Loss कर दिया जायेगा ! यदि प्लेयर में किसी की काटी खुली नहीं तो उसे हम उसे Cancel कर सकते हैं !</small></p>
                                <p><small>यदि एक टोकन बाहर है और घर के पास है तो 30% Loss दिया जायेगा लेकिन यदि गेम खेला गया है और दो काटी बाहर आयी हो तो गेम को लेफ्ट करने वाले का 100% Loss कर दिया जायेगा !</small></p>
                                <p><small>Autoexit में यदि 1 टोकन बाहर है तो गेम कैंसिल किया जा सकता है लेकिन यदि आपने गेम जान बूझकर छोड़ा होगा तो आपको Loss ही दिया जायेगा इसमें अंतिम निर्णय Admin का होगा !</small></p>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    )
}

export default Lobby;

function CreatedRoom(prop) {
    const user_data = LatestUserData();
    const dispatch = useDispatch();
    const WalletSetter = bindActionCreators(ActionCreator, dispatch);
    const Walletselector = useSelector(state => state.Wallet);
    const [GameJoinReq, setGameJoinReq] = useState(false);
    const data = prop.data;
    const battle_id = data.battle_id;

    const amount = data.bet_amount;
    const room_id = data.room_id;
    const battleRemover = prop.battleRemover;
    const battleCreated = prop.AllBattles;
    let count = -1;
    const deleteCreatedGame = () => {
        axios.post("/server/delGame.php", { battle_id: battle_id })
            .then(data => {
                const response = data.data;
                if (response.status === 100) {
                    toast.success(response.msg);
                    let removedBattle = {};
                    battleCreated.forEach(element => {
                        count++;
                        if (element.battle_id === battle_id) {
                            removedBattle = battleCreated.splice(count, 1);
                        }
                        battleRemover([...battleCreated]);
                    });
                    conn.send(JSON.stringify({
                        type: "DELETE_BATTLE",
                        data: removedBattle
                    }));

                } else if (response.status === 101) {
                    toast.error(response.msg);
                }
            })
    }


    const declineJoinReq = () => {
        setGameJoinReq(false);
    }

    return (
        amount && room_id ? (
            <div className="px-4 col-12" style={{ display: "block" }}>
                <div className="betCard mt-1">
                    <span className="betCard-title  pl-3 d-flex align-items-center justify-content-between text-uppercase ">
                        <span className="ml-1" style={{ color: "brown" }}>
                            Playing For
                            <img src="/assets/images/global-rupeeIcon.png" className="ml-1" width="21px" alt="rupeeIcon" />
                            <span className="betCard-amount">{amount}</span>
                        </span>
                        <div className="w-50 text-right">
                            {
                                GameJoinReq ?
                                    <>
                                        <button className="btn btn-success btn-sm p-1" >ACCEPT</button>
                                        <button className="btn btn-danger btn-sm p-1" onClick={declineJoinReq}>REJECT</button>
                                    </> :
                                    <button className="btn btn-danger btn-sm p-1" onClick={deleteCreatedGame}>DELETE</button>
                            }
                        </div>
                    </span>
                    <div className="row betCard-border-top pb-1 pt-2 pl-3">
                        <div className="col-4 text-center">
                            <img src={profile_pic_path + user_data.profile_pic} className="img-fluid" alt="Current User Profile" width="40px" /><br />
                            <small>{user_data.uname}</small>
                        </div>
                        <div className="col-4 text-center">
                            <img src="/assets/images/vs.png" className="img-fluid" alt="Verses" width="40px" />
                        </div>
                        <div className="col-4 text-center">
                            <img src="/assets/images/loader.png" className="img-fluid searching_player" alt="Loading Player" width="40px" /><br />
                            <small>Searching..</small>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    )
}



// Load Different User Created Room
function LoadDifferentChallenges(prop) {
    const user_data = LatestUserData();
    const running_battles = prop.runningBattle;
    const dispatch = useDispatch();
    const [startGameState, setStartGameState] = useState(false);
    const WalletSetter = bindActionCreators(ActionCreator, dispatch);
    const WinWalletselector = useSelector(state => state.WinWallet);
    const ReduxWallet = useSelector(state => state.Wallet);
    const Walletselector = WinWalletselector + ReduxWallet;
    const data = prop.data;
    const battle_id = data.battle_id;
    const amount = data.bet_amount;
    const prize = data.prize;
    const room_id = data.room_id;
    const opponent = data.user1;
    const challenger = data.challenger;
    const cancelled = data.cancelled;
    useEffect(() => {
        if (cancelled) {
            setStartGameState(false);
        }
    }, [cancelled]);
    const startGame = (amount, opponent) => {
        function checkGame(btl) {
            var $user;
            if (btl.user2 == user_data.uid) {
                $user = "user2";
            } else if (btl.user1 == user_data.uid) {
                $user = "user1";
            }
            if ($user) {
                return btl[`winner_claim_${$user}`] ? false : true;
            }
        }
        if (!running_battles.filter(checkGame).length) {
            if (Walletselector >= amount) {
                toast.success("Request Sent Successfully");
                conn.send(JSON.stringify({
                    type: "REQ_BATTLE_JOIN",
                    data: { battle_id, opponent, user_name: user_data.uname, profile_pic: user_data.profile_pic, amount, joiner: user_data.uid },
                }));
                setStartGameState(true);
            } else {
                console.log("Insufficient Balance");
                toast.error("Insufficient Balance");
            }
        } else {
            toast.error("Last Match Not Declared Yet !");
        }
    }

    const cancelGame = (amount, opponent) => {
        conn.send(JSON.stringify({
            type: "REQ_BATTLE_CANCEL",
            data: { battle_id, opponent, user_name: user_data.uname, profile_pic: user_data.profile_pic, amount, joiner: user_data.uid },
        }));
        setStartGameState(false);
    }

    return (
        amount && room_id ? (
            <div className="px-4 col-12">
                <div className="betCard mt-1">
                    <div className="d-flex pl-3">
                        <div>
                            <span className="betCard-subTitle">Entry Fee :</span>
                            <div>
                                <img src="/assets/images/global-rupeeIcon.png" width="21px" alt="rupeeIcon" />
                                <span className="betCard-amount">{amount}</span>
                            </div>
                        </div>
                        <div className="ml-5">
                            <span className="betCard-subTitle">Prize Pool :</span>
                            <div>
                                <img src="/assets/images/global-rupeeIcon.png" width="21px" alt="rupeeIcon" />
                                <span className="betCard-amount">{prize}</span>
                            </div>
                        </div>
                        <div className="d-flex ml-auto mr-3 mt-2 flex-column">
                            <span className="betCard-subTitle d-flex justify-content-end">Entry</span>
                            {
                                startGameState ? (
                                    <button className="bg-danger playButton cxy d-flex" style={{ position: "unset" }} onClick={() => cancelGame(amount, opponent)}  >Cancel</button>
                                ) : (
                                    <button className="bg-green playButton cxy d-flex" style={{ position: "unset" }} onClick={(e) => { startGame(amount, opponent) }} >₹{amount}</button>
                                )
                            }
                        </div>
                    </div>
                    <div className="mt-2">
                    </div>
                    <div className="betCard-title d-flex align-items-center border-top w-100 pl-3 text-uppercase ">
                        <span >
                            <span className="betCard-amount">CHALLENGE FROM</span>
                        </span>
                        <span className="ml-1" style={{ color: "brown" }}>
                            <span className="betCard-amount">{challenger}</span>
                        </span>
                    </div>
                </div>
            </div>
        ) : null
    )
}

const RunningBattles = (prop) => {
    const data = prop.data;
    const user_data = LatestUserData();
    const { accepter, challenger, bet_amount, prize, battle_id, accepter_pic, challenger_pic, user1, user2 } = data;

    return (
        <>
            <div className="px-4 col-12">
                <div className="betCard mt-1">
                    <div className="betCard-title d-flex align-items-center justify-content-between border-top w-100 pl-3 pr-3 text-uppercase ">
                        <div className="d-flex align-items-center">
                            <span className="betCard-amount mr-1">
                                Playing For
                            </span>
                            <img src="/assets/images/global-rupeeIcon.png" width="21px" alt="rupeeIcon" />
                            <span style={{ color: "brown" }}>
                                <span className="betCard-amount">{bet_amount}</span>
                            </span>
                        </div>
                        {
                            user1 === user_data.uid || user2 === user_data.uid ?
                                < Link to={`/room_code/${battle_id}`} className="btn btn-sm result_btn btn-warning text-white">Results</Link>
                                : null
                        }
                        <div className="d-flex align-items-center">
                            <span className="betCard-amount mr-1">
                                Prize
                            </span>
                            <img src="/assets/images/global-rupeeIcon.png" width="21px" alt="rupeeIcon" />
                            <span style={{ color: "brown" }}>
                                <span className="betCard-amount">{prize}</span>
                            </span>
                        </div>
                    </div>
                    <div className="row betCard-border-top pb-1 pt-2 pl-3">
                        <div className="col-4 text-center">
                            <img src={profile_pic_path + challenger_pic} className="img-fluid" alt="Current User Profile" width="30px" /><br />
                            <small>{challenger}</small>
                        </div>
                        <div className="col-4 text-center">
                            <img src="/assets/images/vs.png" className="img-fluid" alt="Verses" width="35px" />
                        </div>
                        <div className="col-4 text-center">
                            <img src={profile_pic_path + accepter_pic} className="img-fluid" alt="Current User Profile" width="30px" /><br />
                            <small>{accepter}</small>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}


// Load Battle Created By Logged User
async function userBattleLoader(self, self_data, different, different_data, running, running_data) {
    const user_data = LatestUserData();
    var self_arr = [];
    var diff_arr = [];
    var running_arr = [];
    axios.post("/server/LoadCreatedBattles.php", { creater: true }).then(data => {
        const response = data.data;
        if (response.status === 100) {
            response.msg.map(data => {
                data.cancelled = false;
                if (data.user1 === user_data.uid && !data.user2) {
                    self_arr.push(data);
                    // self([...self_data, data]);
                } else if (data.user1 !== user_data.uid && !data.user2) {
                    diff_arr.push(data);
                    // different([...different_data, data]);
                } else if (data.user1 && data.user2) {
                    running_arr.push(data);
                }
            });
            running_arr.sort((a, b) => {
                if (a.user1 === user_data.uid || a.user2 === user_data.uid) {
                    return -1;
                }
                if (b.user1 === user_data.uid || a.user2 === user_data.uid) {
                    return 1;
                }
                return 0;
            });

            running(running_arr);
            self(self_arr);
            different(diff_arr);
        }
    });
}