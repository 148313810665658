import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

function Header() {
    const LoginState = useSelector(state => state.login);
    const Wallet = useSelector(state => state.Wallet);
    const Win_Wallet = useSelector(state => state.WinWallet);
    const R_Wallet = useSelector(state => state.R_Wallet);
    
    
    return (
        <>
            <Sidebar />
            <div className="headerContainer" style={{ "background": "#3f7fe2" }}>
                {
                    LoginState ? (
                        <>
                            <Link to="#!" className="side_bar_toggler cxy h-100"  >
                                <picture className="sideNavIcon ml-3 mr-2">
                                    <img src="/assets/images/header-hamburger.png" alt="hamburger" />
                                </picture>
                            </Link>
                            <Link to="/" className="">
                                <picture className="ml-2 navLogo d-flex">
                                    <img src="/assets/images/logo_white.png" className="img-fluid" alt="logo" />
                                </picture>
                            </Link>
                            <div className="menu-items">
                                <Link to="/add-fund" className="mt-2 mx-1 box">
                                    <picture className="moneyIcon-container">
                                        <img src="/assets/images/global-rupeeIcon.png" alt="rupeeIcon" />
                                    </picture>
                                    <div className="mt-1 ml-1">
                                        <div className="moneyBox-header">Deposit</div>
                                        <div className="moneyBox-text">{Wallet}</div>
                                    </div>
                                    <picture className="moneyBox-add">
                                        <img src="/assets/images/global-addSign.png" className="mt-2" alt="addSign" />
                                    </picture>
                                </Link>
                                <Link to="/refer-wallet" className="mt-2 mx-1 box">
                                    <picture className="moneyIcon-container">
                                        <img src="/assets/images/refer-wallet.png" alt="rupeeIcon" />
                                    </picture>
                                    <div className="mt-1 ml-1">
                                        <div className="moneyBox-header">Refer</div>
                                        <div className="moneyBox-text">{R_Wallet}</div>
                                    </div>
                                    <picture className="moneyBox-add">
                                        <img src="/assets/images/rupee_symbol.png" className="mt-2" alt="addSign" />
                                    </picture>
                                </Link>
                            </div>
                        </>

                    ) : (
                        <>
                            <Link to="/" className="">
                                <picture className="ml-2 navLogo d-flex">
                                    <img src="/assets/images/logo_white.png" className="img-fluid" alt="logo" />
                                </picture>
                            </Link>
                            <div className="menu-items">
                                <Link to="/login" className="login-btn border-secondary text-secondary">SIGNUP</Link>
                                <Link to="/login" className="login-btn">LOGIN</Link>
                            </div>
                        </>

                    )
                }
                <span className="mx-5"></span>
            </div>
        </>
    )
}
export default Header;