import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import LatestUserData from "../action/LatestUserData";
import UserData from "../action/UserData";

function CompleteKyc() {
    let user_data = LatestUserData();
    const [FrontPic, setFrontPic] = useState(null);
    
    const handleFileChange = (e) => {
        setFrontPic(e.target.files[0]);
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!FrontPic) {
            toast.error("Please select a photo to upload.");
            return;
        }
    
        const formData = new FormData();
        formData.append("screenshot", FrontPic);

        await axios.post("https://test.telegramludo.com/server/upload_screenshot.php", formData).then(
            data => {
                const response = data.data;
                if (response.status === 100) {
                    UserData();
                    toast.success(response.msg)
                }
                else if (response.status === 101) {
                    toast.error(response.msg);
                }
            }
        );
    };
                
    
    

    const openFileSelector = (selector) => {
        document.getElementById(selector).click();
    };

    return (
        <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="kycPage">
                <div>
                    <span style={{ fontSize: "1.5em" }}>Upload </span>
                </div>
                <p className="mt-2" style={{ color: "rgb(149, 149, 149)" }}>
                   Upload the Screenshot of the transaction.
                
                </p>
                <br />
                <div style={{ marginTop: "10px" }}>
                    <div className="mytext" style={{ fontSize: "1.1em" }}>
                        Ensure that 
                        <span style={{ fontWeight: "700" }}> Transaction Id </span>,
                        <span style={{ fontWeight: "700" }}> Date Time </span>, and
                        <span style={{ fontWeight: "700" }}> Amount </span>
                        are clearly visible in the screenshot.
                    </div>
                </div>
                <div className="doc-upload mt-5">
                    <input
                        id="frontPic"
                        name="frontPic"
                        onChange={handleFileChange}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none'}}
                    />
                    {FrontPic ? (
                        <div className="uploaded">
                            <img src="/assets/images/file-icon.png" width="26px" alt="file-icon" style={{ marginRight: "20px" }} />
                            <div className="d-flex flex-column w-80">
                                <div className="name">{FrontPic.name}</div>
                                <div className="size">{(FrontPic.size / 1000).toFixed(2)} KB</div>
                            </div>
                            <div className="image-block cursor-pointer" onClick={() => openFileSelector("frontPic")}>
                                Change
                            </div>
                        </div>
                    ) : (
                        <div className="cxy flex-column position-absolute cursor-pointer" onClick={() => openFileSelector("frontPic")}>
                            <img src="/assets/images/file-uploader-icon.png" width="17px" alt="file-uploader-icon" />
                            <div className="sideNav-text mt-2">Upload Front Photo</div>
                        </div>
                    )}
                </div>
                <div style={{ paddingBottom: "80px" }}></div>
                <div className="refer-footer">
                    <button className="refer-button cxy w-100 bg-primary" onClick={handleUpload}>
                        Upload
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CompleteKyc;
