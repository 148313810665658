import { bindActionCreators } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LatestUserData from "../action/LatestUserData";
import { conn } from "../action/Socket";
import { ActionCreator } from "../state";


function UploadResult() {
    const params = useParams();
    const user_data = LatestUserData();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const WalletSetter = bindActionCreators(ActionCreator, dispatch);
    const WinWalletselector = useSelector(state => state.WinWallet);
    const Walletselector = useSelector(state => state.Wallet);

    const battle_id = params.battle_id;
    const [ClaimStatus, setClaimStatus] = useState("");
    const [ScreenShot, setScreenShot] = useState(null);
    const [battleData, setbattleData] = useState([]);
    const [opponentId, setopponentId] = useState("");
    const scrollUploadDivToView = () => {
        setTimeout(() => {
            document.getElementById("scrollUploadDivToView").scrollIntoView({ behavior: "smooth" })
        }, 600);
    };

    useEffect(() => {
        GetBattleData(battle_id, (response) => {
            if (response.status === 101) {
                toast.error(response.msg);
            } else if (response.status === 100) {
                setbattleData([response.msg[0]]);
                const opponent = response.msg[0].user2 === user_data.uid ? response.msg[0].user1 : response.msg[0].user2;
                setopponentId(opponent);
            }
        });
    }, [battle_id]);

    const win_parent_class = ClaimStatus === "WIN" ? "bg-success" : "bg-light";
    const lose_parent_class = ClaimStatus === "LOSE" ? "bg-danger" : "bg-light";
    const cancel_parent_class = ClaimStatus === "CANCEL" ? "bg-warning" : "bg-light";
    const win_child_class = ClaimStatus === "WIN" ? "text-white" : "text-success";
    const lose_child_class = ClaimStatus === "LOSE" ? "text-white" : "text-danger";
    const cancel_child_class = ClaimStatus === "CANCEL" ? "text-white" : "text-warning";

    function OpneFileSelector(selector) {
        document.getElementById(selector).click();
    }


    const SubmitClaimForm = () => {
        setClaimStatus("");
        axios.post("/server/ClaimBattle.php", { battle_id: battle_id, claim_status: ClaimStatus })
            .then(data => {
                const response = data.data;
                if (response.status === 101) {
                    toast.error(response.msg);
                }
                else if (response.status === 100) {
                    setbattleData([{ ...battleData[0], ["status"]: 0, ["winner_claim_user1"]: true, ["winner_claim_user2"]: true }]);
                    toast.success(response.msg);
                    setTimeout(() => {
                        navigate(`/lobby`, { replace: true });
                    }, 400);
                }
                else if (response.status === 102) {
                    const winner = response.msg.winner;
                    const win_prize = response.msg.amount;
                    if (winner == user_data.uid) {
                        WalletSetter.Win_WalletAction(WinWalletselector + parseInt(response.msg.amount));
                    } else {
                        conn.send(JSON.stringify({
                            type: "CLAIM_BATTLE",
                            data: { winner, win_prize }
                        }));
                    }
                    setTimeout(() => {
                        navigate(`/lobby`, { replace: true });
                    }, 400);
                } else if (response.status === 103) {
                    conn.send(JSON.stringify({
                        type: "CANCEL_BATTLE",
                        data: { opponent: response.msg.opponent, amount: response.msg.amount }
                    }));
                    WalletSetter.AddWalletAmount(Walletselector , parseInt(response.msg.amount));
                    setTimeout(() => {
                        navigate(`/lobby`, { replace: true });
                    }, 400);
                }
            })
            ;
    }

    const submitScreenShot = () => {
        let formdata = new FormData();
        formdata.append("screenshot", ScreenShot);
        formdata.append("battle_id", battle_id);
        axios.post("/server/UploadGameScreenShot.php", formdata)
            .then(data => {
                const response = data.data;
                if (response.status === 101) {
                    toast.error(response.msg);
                }
                else if (response.status === 100) {
                    setbattleData([{ ...battleData[0], ["status"]: 0, ["winner_claim_user1"]: true, ["winner_claim_user2"]: true }]);
                    toast.success(response.msg);
                } else if (response.status === 102) {
                    const winner = response.msg.winner;
                    const win_prize = response.msg.amount;
                    conn.send(JSON.stringify({
                        type: "CLAIM_BATTLE",
                        data: { winner, win_prize }
                    }));
                }
            });
    }
    useEffect(() => {
        if (ClaimStatus === "CANCEL") {
            document.getElementById("cancel_reason").scrollIntoView({ behavior: "smooth" });
        }
    }, [ClaimStatus]);


    const SubmitClaimFormWithPic = () => {
        if (ClaimStatus === "WIN") {
            if (ScreenShot) {
                submitScreenShot();
                SubmitClaimForm();
            } else {
                toast.error("Screen Shot Required !");
            }
        } else if (ClaimStatus === "LOSE") {
            SubmitClaimForm();
        } else if (ClaimStatus === "CANCEL") {
            if (!document.getElementById("cancel_reason").value.trim()) {
                toast.error("Give Reason for cancelling the game");
            } else {
                SubmitClaimForm();
            }
        }
    }

    console.log("battleData => " ,battleData[0]);
    

    return (
        <>

            {
                // Checking If Battle Exists !!
                battleData.length > 0 ? (
                    // Checking User Has To Claim Win Lose OR Not !!
                    (battleData[0].status == 0) &&
                        ((battleData[0].user1 === user_data.uid && battleData[0].winner_claim_user1) ||
                            (battleData[0].user2 === user_data.uid && battleData[0].winner_claim_user2))
                        ? (
                            <>
                                <WaitForVerification />
                                <h4 className="text-center">You Claimed : {battleData[0][battleData[0].user2 === user_data.uid ? "winner_claim_user2" : "winner_claim_user1"]}</h4>
                            </>
                        )
                        :
                        //  Checking If Admin Has Disapproved Game Status And Asked For Game Result Screen Shot
                        battleData[0].status == 0 ? (
                            <>
                                <div className="px-4 pb-3">
                                    {
                                        ClaimStatus === "WIN" ?
                                            (
                                                <div className="px-4 py-3">
                                                    <span className="d-flex align-items-center profile-wallet bg-light my-4 py-2" style={{ height: "70px" }}>
                                                        <picture className="ml-4">
                                                            <img width="32px" src="/assets/images/alerts.png" alt="alert" />
                                                        </picture>
                                                        <div className="ml-4 mytext text-muted ">
                                                            <span className="font-11 text-center w-100">Game Result ScreenShot<br /></span>
                                                            Provide The Proof ScreenShot Of This Game
                                                        </div>
                                                    </span>
                                                    <p className="font-7 text-center mt-4 mb-2">Admin Has Asked For Game Result ScreenShot</p>
                                                    <div className="doc-upload mt-2">
                                                        <input id="ScreenShot" name="ScreenShot" onChange={e => setScreenShot(e.target.files[0])} type="file" accept="image/*" />
                                                        {
                                                            /*/
                                                                {{ // ! If User Has Selected From Image Of Document  ! }}
                                                            /*/
                                                            ScreenShot ? (
                                                                <>
                                                                    <div className="uploaded">
                                                                        <img src="/assets/images/file-icon.png" width="26px" alt="file-icon" style={{ marginRight: "20px" }} />
                                                                        <div className="d-flex flex-column w-80">
                                                                            <div className="name" style={{ fontSize: "0.9em" }}>
                                                                                {ScreenShot.name}
                                                                            </div>
                                                                            <div className="size">
                                                                                {ScreenShot.size / 1000} KB
                                                                            </div>
                                                                        </div>
                                                                        <div className="image-block" onClick={() => OpneFileSelector("ScreenShot")}>
                                                                            Change
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="cxy flex-column position-absolute">
                                                                    <img src="/assets/images/file-uploader-icon.png" width="17px" alt="file-uploader-icon.png" />
                                                                    <div className="sideNav-text mt-2">
                                                                        Upload ScreenShot Photo
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                            : null
                                    }
                                    {/* <h6>Claim Your Status For This Game : {ClaimStatus}</h6> */}
                                    <div className="row" id="scrollUploadDivToView" >
                                        <div className="col-4 p-1">
                                            <div className={"cursor-pointer card " + win_parent_class} onClick={() => { setClaimStatus("WIN"); scrollUploadDivToView(); }}>
                                                <div className="card-body p-1">
                                                    <label className={"w-100 cursor-pointer m-0 text-center font-weight-normal" + win_child_class} htmlFor="claim_win"><b>Claim Win</b></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 p-1">
                                            <div className={"cursor-pointer card " + lose_parent_class} onClick={() => { setClaimStatus("LOSE") }}>
                                                <div className="card-body p-1">
                                                    <label className={"w-100 cursor-pointer m-0 text-center font-weight-normal" + lose_child_class} htmlFor="claim_lose"><b>Claim Lose</b></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4 p-1">
                                            <div className={"cursor-pointer card " + cancel_parent_class} onClick={() => { setClaimStatus("CANCEL") }}>
                                                <div className="card-body p-1">
                                                    <label className={"w-100 cursor-pointer m-0 text-center font-weight-normal" + cancel_child_class} htmlFor="claim_cancel"><b>Cancel</b></label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            ClaimStatus === "CANCEL" ? (
                                                <>
                                                    <div className="col-12 p-1 mt-2">
                                                        <textarea className="form-control" rows="2" id="cancel_reason" placeholder="Give reason for cancelling the game."></textarea>
                                                    </div>
                                                </>
                                            ) : null
                                        }

                                    </div>
                                </div>
                                {
                                    ClaimStatus ? (
                                        <div className="refer-footer">
                                            <button className="refer-button cxy w-100 bg-primary" onClick={SubmitClaimFormWithPic} >Next</button>
                                        </div>
                                    ) : (
                                        <div className="refer-footer">
                                            <button className="refer-button cxy w-100 bg-secondary">Next</button>
                                        </div>
                                    )
                                }
                            </>
                        ) :

                            //  Checking If Admin Has Disapproved Game Status And Asked For Game Result Screen Shot
                            battleData[0].status === 1 ? (battleData[0].user1 === user_data.uid && battleData[0].file_proof_user1) ||
                                (battleData[0].user2 === user_data.uid && battleData[0].file_proof_user2) ? (
                                <WaitForVerification />
                            ) : (
                                <div className="px-4 py-3">
                                    <span className="d-flex align-items-center profile-wallet bg-light my-4 py-2" style={{ height: "70px" }}>
                                        <picture className="ml-4">
                                            <img width="32px" src="/assets/images/alerts.png" alt="alert" />
                                        </picture>
                                        <div className="ml-4 mytext text-muted ">
                                            <span className="font-11 text-center w-100">Game Result ScreenShot<br /></span>
                                            Provide The Proof ScreenShot Of This Game
                                        </div>
                                    </span>
                                    <p className="font-7 text-center mt-5 mb-2">Admin Has Asked For Game Result ScreenShot</p>
                                    <div className="doc-upload mt-2">
                                        <input id="ScreenShot" name="ScreenShot" onChange={e => setScreenShot(e.target.files[0])} type="file" accept="image/*" />
                                        {
                                            /*/
                                                {{ // ! If User Has Selected From Image Of Document  ! }}
                                            /*/
                                            ScreenShot ? (
                                                <>
                                                    <div className="uploaded">
                                                        <img src="/assets/images/file-icon.png" width="26px" alt="file-icon" style={{ marginRight: "20px" }} />
                                                        <div className="d-flex flex-column w-80">
                                                            <div className="name" style={{ fontSize: "0.9em" }}>
                                                                {ScreenShot.name}
                                                            </div>
                                                            <div className="size">
                                                                {ScreenShot.size / 1000} KB
                                                            </div>
                                                        </div>
                                                        <div className="image-block" onClick={() => OpneFileSelector("ScreenShot")}>
                                                            Change
                                                        </div>
                                                    </div>
                                                    <div className="refer-footer">
                                                        <button className="refer-button cxy w-100 bg-primary" onClick={submitScreenShot} >Next</button>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="cxy flex-column position-absolute">
                                                    <img src="/assets/images/file-uploader-icon.png" width="17px" alt="file-uploader-icon.png" />
                                                    <div className="sideNav-text mt-2">
                                                        Upload ScreenShot Photo
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            ) : battleData[0].status === 2 ? battleData[0].winner === user_data.uid ?
                                (
                                    <div className="cxy flex-column mx-5 mt-5">
                                        <picture className="d-flex justify-content-center" style={{ width: "80%", height: "auto" }}>
                                            <img src="/assets/images/tick.png" alt="tick" style={{ maxWidth: "100%" }} />
                                        </picture>
                                        <div className="font-11 mt-4 text-center">
                                            Game Result Declared
                                            <span className="text-success"> You Have Won.</span>
                                        </div>
                                        <div className="my-3 text-center" style={{ width: "100%" }}>
                                            <div className="footer-text" style={{ fontSize: "0.9em" }}>
                                                Play More Games and Win Further.
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="cxy flex-column mx-5 mt-5">
                                        <picture className="d-flex justify-content-center" style={{ width: "80%", height: "auto" }}>
                                            <img src="/assets/images/WhiteStroke.png" alt="tick" style={{ maxWidth: "100%" }} />
                                        </picture>
                                        <div className="font-11 mt-4 text-center">
                                            Game Result Declared
                                            <span className="text-danger"> You Have Lost.</span>
                                        </div>
                                        <div className="my-3 text-center" style={{ width: "100%" }}>
                                            <div className="footer-text" style={{ fontSize: "0.9em" }}>
                                                Play More Games and Win.
                                            </div>
                                        </div>
                                    </div>
                                )
                                : null

                ) : null
            }

        </>
    )
}

function GetBattleData(battle_id, calback) {
    async function caller() {
        const data = await axios.post("/server/APIBattleData.php", { battle_id: battle_id });
        const response = data.data;
        calback(response);
    }
    caller();
}
export default UploadResult;

function WaitForVerification() {
    return (
        <div className="p-4">
            <div className="cxy flex-column px-4text-center" style={{ "marginTop": "20px" }}>
                <img src="/assets/images/underverfication.png" width="220px" alt="nonotification" />
                <div className="games-section-title mt-4" style={{ "fontSize": "1.2em" }}>Admin Will Respond Soon!</div>
                <div className="games-section-headline mt-2" style={{ "fontSize": "0.85em" }}>Come Back Later To Check Admin`s Response</div>
            </div>
        </div>
    )
}