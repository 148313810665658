import { bindActionCreators } from "@reduxjs/toolkit";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LatestUserData from "../action/LatestUserData";
import { ActionCreator } from "../state";
import { Link } from "react-router-dom";

function AddFund() {
    const user_data = LatestUserData();
    const [amount, setAmount] = useState("");
    const [GoingToPay, setGoingToPay] = useState(false);
    const WalletSelector = useSelector(state => state.Wallet);
    const dispatcher = useDispatch();
    const WalletSetter = bindActionCreators(ActionCreator, dispatcher);

    const ImgPath = "../../upload/qr/1234.png";

    return (

        <div className="main-area" style={{ paddingTop: "60px" }}>
            <div className="px-4 py-3">
                <div className="games-section">
                    <div className="d-flex position-relative align-items-center">
                        <div className="games-section-title">Scan & Pay With Any UPI App</div>
                    </div>
                </div>
                <div className="games-window">
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-12 my-1">
                                <img src={ImgPath} alt="Placeholder" style={{width: '200px', height: '200px'}} />

                                <ol style={{marginTop: "10px"}}>
                                    <li>Scan the Qr Code</li>
                                    <li>Pay the amount you want to add (Min 100)</li>
                                    <li>Upload Transaction Screenshot</li>
                                    <li>Get Your wallet Updated</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="refer-footer">
            <Link to="/PayUpload"><div className="refer-button cxy w-100 bg-primary">Next</div></Link>
            </div>
        </div>
            )
}
 export default AddFund;
